import React, { useEffect } from "react";
import NavbarDesktop from "../../components/navbar/navbarDesktop";
import SidebarComponent from "../../components/navbar";
import Footer from "../../components/footer";
import UpcomingEvents from "./upcomingEvents";

const XceedEvents = () => {
  // const addScriptTag = () => {
  //   const script = document.createElement('script');
  //   script.src = 'https://s3-eu-west-1.amazonaws.com/xceed-widget/2019-version/dist/loader.js';
  //   document.head.appendChild(script);
  // };

  return (
    <div className="eventPage__container">
      <div className="navbarDesktop__eventsPage">
        <NavbarDesktop />
      </div>
      <div className="eventPage__sideBar">
        <SidebarComponent/>
      </div>
      <div>
        <UpcomingEvents showHeader={true}/>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default XceedEvents;
